<template>
  <div class="first-section">
    <div class="col">
      <div class="form-field">
        <h4>{{ $t(`EVENT_VALUES.START_DATE`) }}</h4>
        <DatePicker
          v-model.number="state.start_date"
          mode="dateTime"
          :min-date="Date.now() - 14 * 24 * 60 * 60 * 1000"
          :model-config="{
            type: 'number',
            mask: 'DD/MM/YYYY HH:mm',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="input"
              id="start_date"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </DatePicker>
      </div>
      <div class="form-field">
        <h4>{{ $t(`EVENT_VALUES.END_DATE`) }}</h4>
        <DatePicker
          v-model.number="state.end_date"
          mode="dateTime"
          :min-date="Date.now() - 7 * 24 * 60 * 60 * 1000"
          :model-config="{
            type: 'number',
            mask: 'DD/MM/YYYY HH:mm',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="input"
              id="start_date"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </DatePicker>
      </div>
      <div class="form-field">
        <h4>Unlocked Arena</h4>
        <input class="input" v-model="state.unlocked_arena" type="number" />
      </div>
      <div class="form-field">
        <h4>Event Specific Unit</h4>
        <select class="input" v-model="state.event_unit_id">
          <option
            v-for="unit in units.filter((x) => x.status === 1)"
            v-bind:key="unit.id"
            :value="unit.id"
          >
            {{ unit.name }}
          </option>
        </select>
      </div>
      <div class="form-field">
        <h4>Initial Soft Currency</h4>
        <input
          class="input"
          v-model="state.initial_soft_currency"
          type="number"
        />
      </div>
      <div class="form-field">
        <h4>Currency Gold Conversion Rate</h4>
        <input
          class="input"
          v-model="state.currency_gold_conversion_rate"
          type="number"
        />
      </div>
      <div>
        <h3>Collections</h3>
        <h4>Silver</h4>
        <div class="form-field">
          <h5>Exchange Token Count</h5>
          <input
            class="input"
            v-model="state.collections['0'].exchange_token_count"
            type="number"
          />
        </div>
        <h4>Gold</h4>
        <div class="form-field">
          <h5>Exchange Token Count</h5>
          <input
            class="input"
            v-model="state.collections['1'].exchange_token_count"
            type="number"
          />
        </div>
        <h4>Diamond</h4>
        <div class="form-field">
          <h5>Exchange Token Count</h5>
          <input
            class="input"
            v-model="state.collections['2'].exchange_token_count"
            type="number"
          />
        </div>
      </div>
      <h3>Localization</h3>
      <div class="form-field">
        <h4>Language</h4>
        <select class="input" v-model="selectedLanguage">
          <option
            v-for="language in languages"
            v-bind:key="language.code"
            :value="language.code"
          >
            {{ language.name }}
          </option>
        </select>
      </div>
      <div class="form-field">
        <h4>Name</h4>
        <input
          class="input"
          v-model="state.localizables.name[selectedLanguage]"
        />
      </div>
      <div class="form-field">
        <h4>Soft Currency Name</h4>
        <input
          class="input"
          v-model="state.localizables.soft_currency_name[selectedLanguage]"
        />
      </div>
      <div class="form-field">
        <h4>Hard Currency Name</h4>
        <input
          class="input"
          v-model="state.localizables.hard_currency_name[selectedLanguage]"
        />
      </div>
      <div class="form-field">
        <h4>Currency For Ad Text</h4>
        <input
          class="input"
          v-model="state.localizables.currency_for_ad[selectedLanguage]"
        />
      </div>
      <div class="form-field">
        <h4>More Currency Text</h4>
        <input
          class="input"
          v-model="state.localizables.more_currency[selectedLanguage]"
        />
      </div>
      <div class="form-field">
        <h4>Shop Title</h4>
        <input
          class="input"
          v-model="state.shop.localizables.shop_title[selectedLanguage]"
        />
      </div>
      <div class="form-field">
        <h4>Hard Currency Offers Title</h4>
        <input
          class="input"
          v-model="
            state.shop.localizables.hard_currency_offers_title[selectedLanguage]
          "
        />
      </div>
      <div class="form-field">
        <h4>Roulette Milestone Tooltip</h4>
        <input
          class="input"
          v-model="
            state.localizables.milestone_tooltip[selectedLanguage]
          "
        />
      </div>
      <div class="form-field">
        <h4>Hard Currency Tooltip</h4>
        <input
          class="input"
          v-model="
            state.localizables.hard_currency_tooltip_text[selectedLanguage]
          "
        />
      </div>
      <div class="form-field">
        <h4>Soft Currency Tooltip</h4>
        <input
          class="input"
          v-model="
            state.localizables.soft_currency_tooltip_text[selectedLanguage]
          "
        />
      </div>
      <div class="form-field">
        <h4>Event Token Tooltip</h4>
        <input
          class="input"
          v-model="
            state.localizables.event_token_tooltip_text[selectedLanguage]
          "
        />
      </div>
    </div>
    <div class="col">
      <div class="form-field">
        <h4>Language</h4>
        <select class="input" v-model="selectedLanguage">
          <option
            v-for="language in languages"
            v-bind:key="language.code"
            :value="language.code"
          >
            {{ language.name }}
          </option>
        </select>
      </div>
      <info-fields
        v-if="state.info_fields"
        :selectedLanguage="selectedLanguage"
        :data="{ info_fields: state.info_fields }"
        :onChanged="(value) => onInfoFieldsChanged(value)"
      />

      <h3>Event Addressables</h3>

      <h4>Banner Assets</h4>
      <div class="form-fields">
        <p>{{ "Event Page Asset" }}</p>
        <multiselect
          v-model="state.event_assets.banner_assets.event_page_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>
      <div class="form-fields">
        <p>{{ "Event Pass Asset" }}</p>
        <multiselect
          v-model="state.event_assets.banner_assets.event_pass_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>
      <div class="form-fields">
        <p>{{ "Event Pass Offer Popup Asset" }}</p>
        <multiselect
          v-model="
            state.event_assets.banner_assets.event_pass_offer_popup_asset
          "
          :options="assetOptions"
          :multiple="false"
        />
      </div>
      <div class="form-fields">
        <p>{{ "Event Pass Offer Shop Asset" }}</p>
        <multiselect
          v-model="state.event_assets.banner_assets.event_pass_offer_shop_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>
      <div class="form-fields">
        <p>{{ "Event Popup Asset" }}</p>
        <multiselect
          v-model="state.event_assets.banner_assets.event_popup_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>

      <h4>Currency Assets</h4>
      <div class="form-fields">
        <p>{{ "Event Pass Token Asset" }}</p>
        <multiselect
          v-model="state.event_assets.currency_assets.event_pass_token_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>
      <div class="form-fields">
        <p>{{ "Hard Currency Asset" }}</p>
        <multiselect
          v-model="state.event_assets.currency_assets.hard_currency_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>
      <div class="form-fields">
        <p>{{ "Soft Currency Asset" }}</p>
        <multiselect
          v-model="state.event_assets.currency_assets.soft_currency_asset"
          :options="assetOptions"
          :multiple="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import "v-calendar/dist/style.css";

import languageConstants from "@/constants/languagesTemp";
import eventConstants from "@/constants/eventConstants";
import unitConstants from "@/constants/unitConstants";
import InfoFields from "../../common/InfoFields.vue";
import { useStore } from "vuex";

export default {
  name: "ThematicLiveOpsMain",
  props: {
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
  },
  components: {
    InfoFields,
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));
    const selectedLanguage = ref("en");

    const store = useStore();
    const units = computed(() => store.getters["units/getUnits"]);

    watch(
      state,
      (newData) => {
        if (newData && Object.keys(newData).length > 0)
          props.onChanged(newData);
      },
      { immediate: true }
    );

    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined,
        },
        ...store.getters["assets/getAssets"].files,
      ];
    });

    watch(
      () => store.getters["assets/getAssets"],
      (assets) => {
        assetList.value = assets.files;
      }
    );

    const assetOptions = computed(() => {
      let assetArray = [];
      assetList.value.forEach((asset) => {
        if (asset.doc_ref === undefined) {
          assetArray.push("DEFAULT BANNER");
        } else {
          assetArray.push(asset.doc_ref);
        }
      });
      return assetArray;
    });

    const onInfoFieldsChanged = (value) => {
      state.info_fields = value.info_fields;
    };

    return {
      state,
      languages: languageConstants.languages,
      constants: {
        REWARD_TYPES: eventConstants.EVENT_REWARD_TYPES,
        CARD_RARITY: unitConstants.RARITY,
        INFO_FIELD_ICONS: eventConstants.INFO_FIELD_ICONS,
      },
      onInfoFieldsChanged,
      selectedLanguage,
      units,
      assetOptions,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

.input-multi {
  width: 300px;
  height: 35px;
}

.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.flex {
  display: flex;
}
</style>