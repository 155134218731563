<template>
  <div class="container">
    <div class="form-field">
      <h4>Spin Price (Soft Currency / Spin)</h4>
      <input class="input" v-model="state.spin_price" type="number" />
    </div>
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
      "
    >
      <h3>Milestone Rewards</h3>
      <Button
        size="sm"
        text="Add"
        :loading="false"
        :disabled="isEventActive"
        type="success"
        :onClick="() => addSpinMilestoneReward()"
      />
    </div>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Type
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Rarity
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Specific Item
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Amount
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Avatar Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Emoji Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Spin Count
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Actions
        </div>
      </div>
      <div
        v-for="(reward, index) in state.spin_milestone_rewards"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model.number="state.spin_milestone_rewards[index].reward.type"
            @change="
              initializeMilestoneRewardType(
                index,
                state.spin_milestone_rewards[index].reward.type
              )
            "
          >
            <option
              v-for="option in rewardTypes"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model.number="state.spin_milestone_rewards[index].reward.rarity"
            :disabled="
              ![3, 4, 20].includes(
                state.spin_milestone_rewards[index].reward.type
              )
            "
          >
            <option
              v-for="option in state.spin_milestone_rewards[index].reward
                .type == 20
                ? rewardConstants.ORNAMENTS_RARITY
                : constants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model="state.spin_milestone_rewards[index].reward.id"
            :disabled="
              ![2, 3, 4].includes(
                state.spin_milestone_rewards[index].reward.type
              )
            "
          >
            <option
              v-for="option in getSpecificRewardOptions(state.spin_milestone_rewards[index].reward.type)"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="
              [6, 14].includes(state.spin_milestone_rewards[index].reward.type)
            "
            type="number"
            v-model.number="state.spin_milestone_rewards[index].reward.amount"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="state.spin_milestone_rewards[index].reward.type !== 14"
            type="number"
            v-model.number="
              state.spin_milestone_rewards[index].reward.avatar_index
            "
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="state.spin_milestone_rewards[index].reward.type !== 6"
            type="number"
            v-model.number="
              state.spin_milestone_rewards[index].reward.emoji_index
            "
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            v-model.number="state.spin_milestone_rewards[index].spin_amount"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <Button
            size="sm"
            text="Remove"
            :loading="false"
            type="error"
            :onClick="() => removeMilestoneSpinReward(index)"
          />
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
      "
    >
      <h3>Spin Rewards</h3>
      <Button
        size="sm"
        text="Add"
        :loading="false"
        :disabled="false"
        type="success"
        :onClick="() => addSpinReward()"
      />
    </div>
    <div class="table">
      <div class="row row-header">
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Type
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Rarity
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Specific Item
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Amount
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Avatar Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Emoji Index
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Probability Weight
        </div>
        <div class="cell cell-header" :style="{ width: 100 / 8 + '%' }">
          Actions
        </div>
      </div>
      <div
        v-for="(reward, index) in state.spin_rewards"
        v-bind:key="index"
        class="row"
      >
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model.number="state.spin_rewards[index].reward.type"
            @change="
              initializeRewardType(index, state.spin_rewards[index].reward.type)
            "
          >
            <option
              v-for="option in rewardTypes"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model.number="state.spin_rewards[index].reward.rarity"
            :disabled="
              ![3, 4, 20].includes(state.spin_rewards[index].reward.type)
            "
          >
            <option
              v-for="option in state.spin_rewards[index].reward.type == 20
                ? rewardConstants.ORNAMENTS_RARITY
                : constants.CARD_RARITY"
              :value="option.value"
              v-bind:key="option.value"
            >
              {{ $t("REWARD_VALUES." + option.text) }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <select
            v-model="state.spin_rewards[index].reward.id"
            :disabled="![2, 3].includes(state.spin_rewards[index].reward.type)"
          >
            <option
              v-for="option in state.spin_rewards[index].reward.type == 2
                ? chests
                : [
                    { id: undefined, name: 'Random' },
                    ...units.filter((x) => x.status === 1),
                  ]"
              :value="option.id"
              v-bind:key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="[6, 14].includes(state.spin_rewards[index].reward.type)"
            type="number"
            v-model.number="state.spin_rewards[index].reward.amount"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="state.spin_rewards[index].reward.type !== 14"
            type="number"
            v-model.number="state.spin_rewards[index].reward.avatar_index"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input
            :disabled="state.spin_rewards[index].reward.type !== 6"
            type="number"
            v-model.number="state.spin_rewards[index].reward.emoji_index"
          />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <input v-model.number="state.spin_rewards[index].probability" />
        </div>
        <div class="cell" :style="{ width: 100 / 8 + '%' }">
          <Button
            size="sm"
            text="Remove"
            :loading="false"
            type="error"
            :onClick="() => removeSpinReward(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from "vue";
import rewardConstants from "../../../../constants/rewardConstants";
import unitConstants from "../../../../constants/unitConstants";
import Button from "../../../../components/common/Button.vue";
import { useStore } from "vuex";

export default {
  props: {
    availableRewardTypes: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
    isEventActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Button,
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));
    const rewardTypes = rewardConstants.ALL_REWARD_TYPES.filter((x) =>
      props.availableRewardTypes.includes(x.value)
    );
    const store = useStore();

    const chests = computed(() => store.getters["chests/getChests"]);
    const units = computed(() => store.getters["units/getUnits"]);
    const heroes = computed(() => store.getters["heroes/getHeroes"]);

    watch(
      state,
      (newData) => {
        if (newData) props.onChanged(newData);
      },
      { immediate: true }
    );

    const addSpinReward = () => {
      state.spin_rewards.push({
        reward: { type: 0, amount: 0 },
        probability: 0,
      });
    };

    const removeSpinReward = (index) => {
      state.spin_rewards.splice(index, 1);
    };

    const addSpinMilestoneReward = () => {
      state.spin_milestone_rewards.push({
        reward: { type: 0, amount: 0 },
        spin_amount: 0,
      });
    };

    const removeMilestoneSpinReward = (index) => {
      state.spin_milestone_rewards.splice(index, 1);
    };

    const initializeRewardType = (index, type) => {
      const reward = {
        type,
        amount: 0,
      };

      if ([3, 4, 20].includes(type)) reward.rarity = 0;
      if (type === 2) reward.id = "";

      if ([6, 14].includes(type)) delete reward.amount;

      state.spin_rewards[index].reward = reward;
    };

    const initializeMilestoneRewardType = (index, type) => {
      const reward = {
        type,
        amount: 0,
      };

      if ([3, 4, 20].includes(type)) reward.rarity = 0;
      if (type === 2) reward.id = "";

      if ([6, 14].includes(type)) delete reward.amount;

      state.spin_milestone_rewards[index].reward = reward;
    };

    const getSpecificRewardOptions = (type) => {
      switch (type) {
        case 2:
          return chests.value;
        case 3:
          return [
            { id: undefined, name: "Random" },
            ...units.value.filter((x) => x.status === 1),
          ];
        case 4:
          return [
            { id: undefined, name: "Random" },
            ...heroes.value.filter((x) => x.status === 1),
          ];
        default:
          return [];
      }
    };

    return {
      state,
      addSpinReward,
      removeSpinReward,
      initializeRewardType,
      initializeMilestoneRewardType,
      rewardTypes,
      constants: {
        CARD_RARITY: unitConstants.RARITY,
      },
      chests,
      rewardConstants,
      units,
      addSpinMilestoneReward,
      getSpecificRewardOptions,
      removeMilestoneSpinReward,
    };
  },
};
</script>


<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
}

.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 70px;
  overflow: hidden;
}

.cell.cell-header {
  font-size: 0.95rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  width: 100%;
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  margin-bottom: 50px;
}

.table select {
  width: 90%;
  height: 35px;
}

.table input {
  width: 90%;
  height: 35px;
}

.flex {
  display: flex;
}

.margin {
  margin: 10px;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

.form-field {
  text-align: left;
}
input {
  width: 90%;
  min-width: 100px;
  height: 35px;
}
</style>