<template>
  <div class="thematic-shop-container">
    <div class="thematic-shop-section">
      <div
        class="header-with-button clickable"
        :class="sectionStates.currency_offers ? 'open' : ''"
        :onclick="
          () => {
            sectionStates.currency_offers = !sectionStates.currency_offers;
          }
        "
      >
        <h3>Currency Offers</h3>
        <Button
          text="Add"
          type="success"
          :disabled="isEventActive"
          :onClick="(event) => addCurrencyOffer(event)"
        />
      </div>
      <div v-if="sectionStates.currency_offers">
        <div
          class="thematic-shop-reward"
          v-for="(offer, index) in state.shop.currency_offers"
          v-bind:key="index"
        >
          <reward
            :hasHeader="true"
            :data="offer.reward"
            :availableRewardTypes="availableRewardTypes"
            :onChanged="
              (reward) =>
                onRewardChanged('currency_offers', 'reward', reward, index)
            "
          />
          <div class="form-field">
            <h4>Price</h4>
            <input class="input" v-model="offer.price" type="number" />
          </div>
          <div class="form-field">
            <h4>Limit</h4>
            <input class="input" v-model="offer.limit" type="number" />
          </div>
          <div class="form-field">
            <h4>Unlock Day</h4>
            <input class="input" v-model="offer.unlock_day" type="number" />
          </div>
        </div>
      </div>
    </div>
    <div class="thematic-shop-section">
      <div
        class="header-with-button clickable"
        :class="sectionStates.gem_offers ? 'open' : ''"
        :onclick="
          () => {
            sectionStates.gem_offers = !sectionStates.gem_offers;
          }
        "
      >
        <h3>Gem Offers</h3>
        <Button
          text="Add"
          type="success"
          :disabled="isEventActive"
          :onClick="(event) => addGemOffer(event)"
        />
      </div>
      <div v-if="sectionStates.gem_offers">
        <div
          class="thematic-shop-reward"
          v-for="(offer, index) in state.shop.gem_offers"
          v-bind:key="index"
        >
          <div class="header-with-button">
            <h4>Rewards</h4>
            <Button
              size="sm"
              text="Add"
              type="success"
              :onClick="
                () => {
                  offer.rewards.push({ type: 0, amount: 0, amount_type: 0 });
                }
              "
            />
          </div>
          <reward
            v-for="(reward, rewardIndex) of offer.rewards"
            v-bind:key="rewardIndex"
            :hasHeader="rewardIndex === 0"
            :data="reward"
            :availableRewardTypes="availableRewardTypes"
            :onChanged="
              (editedReward) =>
                onRewardChanged(
                  'gem_offers',
                  'rewards',
                  editedReward,
                  index,
                  rewardIndex
                )
            "
            :onRemove="
              () => {
                offer.rewards.splice(rewardIndex, 1);
              }
            "
          />
          <div class="form-field">
            <p>{{ "Shop Asset" }}</p>
            <multiselect
              v-model="offer.asset_ref"
              :options="assetOptions"
              :multiple="false"
            />
          </div>
          <div class="form-field">
            <h4>Title</h4>
            <input class="input" v-model="offer.title" />
          </div>
          <div class="form-field">
            <p>Language</p>
            <select class="input" v-model="selectedLanguage">
              <option
                v-for="language in languages"
                v-bind:key="language.code"
                :value="language.code"
              >
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="form-field">
            <h4>Localized Title</h4>
            <input
              class="input"
              v-model="offer.localizables.title[selectedLanguage]"
            />
          </div>
          <div class="form-field">
            <h4>Price</h4>
            <input class="input" v-model="offer.price" type="number" />
          </div>
          <div class="form-field">
            <h4>Limit</h4>
            <input class="input" v-model="offer.limit" type="number" />
          </div>
          <div class="form-field">
            <h4>Unlock Day</h4>
            <input class="input" v-model="offer.unlock_day" type="number" />
          </div>
        </div>
      </div>
    </div>
    <div class="thematic-shop-section">
      <div
        class="header-with-button clickable"
        :class="sectionStates.gem_offers ? 'open' : ''"
        :onclick="
          () => {
            sectionStates.iap_offers = !sectionStates.iap_offers;
          }
        "
      >
        <h3>IAP Offers</h3>
        <Button
          text="Add"
          type="success"
          :disabled="isEventActive"
          :onClick="
            () => {
              addIapOffer();
              editIapOffer(state.shop.iap_offers.length - 1);
            }
          "
        />
      </div>
      <div
        v-for="(offer, index) of state.shop.iap_offers"
        v-bind:key="index"
        class="thematic-iap-offer-item"
      >
        <p>{{ offer.title }}</p>
        <div class="flex">
          <Button
            text="Edit"
            size="sm"
            type="info"
            :onClick="() => editIapOffer(index)"
          />
          <Button
            text="Remove"
            size="sm"
            type="error"
            :onClick="
              () => {
                state.shop.iap_offers.splice(index, 1);
              }
            "
          />
        </div>
      </div>
      <Modal v-if="modalState" @close="() => closeModal()" size="lg">
        <template v-slot:header>{{
          state.shop.iap_offers[editingIapOfferIndex].title
        }}</template>
        <template v-slot:body>
          <div class="iap-offer-body">
            <div>
              <div class="form-field">
                <p>Language</p>
                <select class="input" v-model="selectedLanguage">
                  <option
                    v-for="language in languages"
                    v-bind:key="language.code"
                    :value="language.code"
                  >
                    {{ language.name }}
                  </option>
                </select>
              </div>
              <div class="form-field">
                <p>Title</p>
                <input
                  v-model="state.shop.iap_offers[editingIapOfferIndex].title"
                />
              </div>
              <div class="form-field">
                <p>Localized Title</p>
                <input
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].localizables
                      .title[selectedLanguage]
                  "
                />
              </div>
              <div class="form-field">
                <p>Product Id</p>
                <input
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].product_id
                  "
                />
              </div>
              <div class="form-field">
                <p>Price</p>
                <input
                  v-model="state.shop.iap_offers[editingIapOfferIndex].price"
                  type="number"
                />
              </div>
              <div class="form-field">
                <p>Discount Rate</p>
                <input
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].discount_rate
                  "
                  type="number"
                />
              </div>
              <div class="form-field">
                <p>Value Multiplier</p>
                <input
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].value_multiplier
                  "
                  type="number"
                />
              </div>
              <div class="form-field">
                <p>Unlock Day</p>
                <input
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].unlock_day
                  "
                  type="number"
                />
              </div>
              <div class="form-field">
                <p>Expires in (Hours)</p>
                <input
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].expires_in
                  "
                  type="number"
                />
              </div>
            </div>
            <div>
              <div class="form-container">
                <p>{{ "Layout" }}</p>
                <select
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].layout_index
                  "
                >
                  <option
                    v-for="option in promotionConstants.LAYOUT_OPTIONS"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text || "DEFAULT" }}
                  </option>
                </select>
              </div>
              <div class="form-container">
                <p>{{ "Popup Type" }}</p>
                <select
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex].popup_type
                  "
                >
                  <option
                    v-for="option in promotionConstants.POPUP_TYPE_OPTIONS"
                    :value="option.value"
                    v-bind:key="option.value"
                  >
                    {{ option.text || "DEFAULT" }}
                  </option>
                </select>
              </div>
              <div class="form-container">
                <p>{{ $t(`PROMOTION_VALUES.SHOP_BANNER_ASSET`) }}</p>
                <multiselect
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex]
                      .shop_banner_asset
                  "
                  :options="assetOptions"
                  :multiple="false"
                />
              </div>
              <div class="form-container">
                <p>{{ $t(`PROMOTION_VALUES.POPUP_BANNER_ASSET`) }}</p>
                <multiselect
                  v-model="
                    state.shop.iap_offers[editingIapOfferIndex]
                      .popup_banner_asset
                  "
                  :options="assetOptions"
                  :multiple="false"
                />
              </div>
              <div class="header-with-button">
                <p>Rewards</p>
                <Button
                  type="success"
                  size="sm"
                  text="Add"
                  :onClick="
                    () => {
                      state.shop.iap_offers[editingIapOfferIndex].rewards.push({
                        type: 0,
                        amount: 0,
                        amount_type: 0,
                      });
                    }
                  "
                />
              </div>
              <reward
                v-for="(reward, rewIndex) of state.shop.iap_offers[
                  editingIapOfferIndex
                ].rewards"
                :data="reward"
                v-bind:key="rewIndex"
                :hasHeader="rewIndex === 0"
                :availableRewardTypes="[0, 1, 2, 3, 4, 17, 20]"
                :onChanged="
                  (editedReward) =>
                    onRewardChanged(
                      'iap_offers',
                      'rewards',
                      editedReward,
                      editingIapOfferIndex,
                      rewIndex
                    )
                "
                :onRemove="
                  () => {
                    state.shop.iap_offers[editingIapOfferIndex].rewards.splice(
                      rewIndex,
                      1
                    );
                  }
                "
              />
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import Reward from "../../../../components/common/Reward.vue";
import Button from "../../../../components/common/Button.vue";
import { useModal } from "../../../../composables/modal";
import Modal from "@/components/common/Modal.vue";
import iapOfferSchema from "@/schemas/thematicIapOffer";
import languageConstants from "@/constants/languagesTemp";
import promotionConstants from "@/constants/promotionConstants";
import { useStore } from "vuex";

export default {
  components: {
    Modal,
    Reward,
    Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
    isEventActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));
    const availableRewardTypes = [0, 1, 2, 3, 4, 6, 20];
    const selectedLanguage = ref("en");
    const editingIapOfferIndex = ref(-1);
    const sectionStates = reactive({
      currency_offers: false,
      gem_offers: false,
      iap_offers: false,
    });

    const { modalState, openModal, closeModal } = useModal();

    const store = useStore();
    const assetList = computed(() => {
      return [
        {
          doc_ref: undefined,
        },
        ...store.getters["assets/getAssets"].files,
      ];
    });

    watch(
      () => store.getters["assets/getAssets"],
      (assets) => {
        assetList.value = assets.files;
      }
    );

    const assetOptions = computed(() => {
      let assetArray = [];
      assetList.value.forEach((asset) => {
        if (asset.doc_ref === undefined) {
          assetArray.push("DEFAULT BANNER");
        } else {
          assetArray.push(asset.doc_ref);
        }
      });
      return assetArray;
    });

    watch(
      state,
      (newData) => {
        if (newData) props.onChanged(newData);
      },
      { immediate: true }
    );

    const onRewardChanged = (
      key,
      rewardKey,
      reward,
      index,
      rewardIndex = -1
    ) => {
      if (rewardIndex === -1) state.shop[key][index][rewardKey] = reward;
      else state.shop[key][index][rewardKey][rewardIndex] = reward;
    };

    const addCurrencyOffer = (event) => {
      event.stopPropagation();
      state.shop.currency_offers.push({
        reward: { type: 0, amount: 0, amount_type: 0 },
        price: 0,
        limit: 0,
        unlock_day: 0,
      });
    };

    const addGemOffer = (event) => {
      event.stopPropagation();
      state.shop.gem_offers.push({
        title: "",
        asset_ref: "",
        localizables: { title: {} },
        rewards: [],
        price: 0,
        limit: 0,
        unlock_day: 0,
      });
    };

    const addIapOffer = () => {
      state.shop.iap_offers.push(iapOfferSchema());
    };

    const editIapOffer = (index) => {
      editingIapOfferIndex.value = index;
      openModal();
    }

    return {
      state,
      availableRewardTypes,
      onRewardChanged,
      addCurrencyOffer,
      addGemOffer,
      sectionStates,
      addIapOffer,
      selectedLanguage,
      languages: languageConstants.languages,
      promotionConstants,
      assetOptions,
      modalState,
      editIapOffer,
      editingIapOfferIndex,
      closeModal
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.thematic-shop-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
}

.thematic-shop-section {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  text-align: left;
  flex-direction: column;
}

.thematic-shop-reward {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid black;
}

.form-field {
  padding: 0px 5px;
  text-align: left;
}

.header-with-button {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-with-button.clickable.open {
  background-color: rgb(238, 238, 238);
}

.header-with-button.clickable:hover {
  background-color: lightgrey;
  cursor: pointer;
}

.thematic-iap-offer-item {
  padding: 0px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  flex-direction: row;
}

.form-field {
  text-align: left;
}

input {
  min-width: 100px;
  height: 35px;
}

select {
  min-width: 100px;
  height: 35px;
}

.iap-offer-body {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
</style>