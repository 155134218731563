import { ref } from "vue";


export function useThematicLiveops() {
    const isEventActive = ref(true);

    function setIsEventActive(value) {
        isEventActive.value = value;
    }

    return { isEventActive, setIsEventActive };
}