export default () => {
    return {
        title: 'Pack Title',
        localizables: {
            title: {}
        },
        product_id: '',
        expires_in: 0,
        price: 0,
        discount_rate: 0,
        reward_claim_type: 0,
        rewards: [{ amount: 0, type: 0, amount_type: 0 }],
        value_multiplier: 0,
        layout_index: 0,
        popup_type: 0,
        unlock_day: 0
    }
}