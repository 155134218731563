<template>
  <div class="thematic-pass-container">
    <div class="thematic-pass-tier header">
      <h4>Token</h4>
      <h4>Free Reward</h4>
      <h4>Premium Reward</h4>
      <h4>Actions</h4>
    </div>
    <div
      class="thematic-pass-tier"
      v-for="(tier, index) of Object.keys(state.event_pass_road.ep_rewards)"
      v-bind:key="index"
    >
      <p class="cell">{{ tier }}</p>
      <reward
        :hasHeader="index === 0"
        :availableRewardTypes="availableRewardTypes"
        :data="state.event_pass_road.ep_rewards[tier]"
        :onChanged="(editedReward) => updateReward(editedReward, tier, true)"
        :probabilityEditable="false"
        :amountTypeEditable="false"
      />
      <div class="border-left">
        <reward
          :hasHeader="index === 0"
          :availableRewardTypes="availableRewardTypes"
          :data="state.event_pass_road.epp_rewards[tier]"
          :onChanged="(editedReward) => updateReward(editedReward, tier, false)"
          :probabilityEditable="false"
          :amountTypeEditable="false"
        />
      </div>
      <div class="cell" style="margin-bottom: 10px;">
        <Button
          text="Remove"
          type="error"
          :onClick="() => removeTier(tier)"
          :disabled="isEventActive"
          size="md"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import Reward from "../../../../components/common/Reward.vue";
import Button from "../../../../components/common/Button.vue";

export default {
  name: "ThematicEventPass",
  components: {
    Reward,
    Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
    isEventActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));
    const availableRewardTypes = [0, 1, 2, 3, 4, 6, 14, 17, 18, 20];

    watch(
      state,
      (newData) => {
        if (newData) props.onChanged(newData);
      },
      { immediate: true }
    );

    const addTier = (tierToken) => {
      state.event_pass_road.ep_rewards[tierToken] = {
        amount: 0,
        type: 0,
        amount_type: 0,
      };
      state.event_pass_road.epp_rewards[tierToken] = {
        amount: 0,
        type: 0,
        amount_type: 0,
      };
    };

    const removeTier = (tierToken) => {
      delete state.event_pass_road.ep_rewards[tierToken];
      delete state.event_pass_road.epp_rewards[tierToken];
    };

    const updateReward = (updatedReward, tier, isFreeReward) => {
      state.event_pass_road[isFreeReward ? "ep_rewards" : "epp_rewards"][tier] =
        updatedReward;
    };

    return {
      state,
      availableRewardTypes,
      addTier,
      updateReward,
      removeTier,
    };
  },
};
</script>

<style scoped>
.thematic-pass-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
}

.thematic-pass-tier {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 6fr 6fr 1fr;
}

.cell {
    width: 100%;
    align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.header {
  font-weight: bold;
  border-bottom: 1px solid gray;
}

.border-left {
  border-left: 1px solid gray;
}
</style>