<template>
  <div>
    <div v-if="hasHeader" class="reward-container">
      <div class="cell underline">Type</div>
      <div class="cell underline">Amount</div>
      <div class="cell underline">Rarity</div>
      <div class="cell underline">Specific Item</div>
      <div class="cell underline" v-if="amountTypeEditable">Amount Type</div>
      <div class="cell underline" v-if="amountTypeEditable">Min Amount</div>
      <div class="cell underline" v-if="probabilityEditable">Probability</div>
      <div class="cell underline" v-if="onRemove">Actions</div>
    </div>
    <div class="reward-container">
      <div class="cell">
        <select
          v-model.number="state.reward.type"
          @change="initializeRewardType(state.reward.type)"
        >
          <option
            v-for="option in rewardTypes"
            :value="option.value"
            v-bind:key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
      <div class="cell">
        <input
          :disabled="[6, 14].includes(state.reward.type)"
          v-model="state.reward.amount"
          type="number"
        />
      </div>
      <div class="cell">
        <select
          v-model.number="state.reward.rarity"
          :disabled="![3, 4, 20].includes(state.reward.type)"
        >
          <option
            v-for="option in state.reward.type == 20
              ? rewardConstants.ORNAMENTS_RARITY
              : constants.CARD_RARITY"
            :value="option.value"
            v-bind:key="option.value"
          >
            {{ $t("REWARD_VALUES." + option.text) }}
          </option>
        </select>
      </div>
      <div class="cell">
        <select
          v-if="[2, 3, 4].includes(state.reward.type)"
          v-model="state.reward.id"
        >
          <option
            v-for="option in getIdColOption(state.reward.type)"
            :value="option.id"
            v-bind:key="option.id"
          >
            {{ option.name }}
          </option>
        </select>
        <input
          v-else
          :disabled="![6, 14].includes(state.reward.type)"
          v-model="
            state.reward[
              state.reward.type == 6 ? 'emoji_index' : 'avatar_index'
            ]
          "
          type="number"
        />
      </div>
      <div v-if="amountTypeEditable" class="cell">
        <select
          v-model.number="state.reward.amount_type"
          :disabled="[6, 14].includes(state.reward.type)"
        >
          <option
            v-for="option in rewardConstants.AMOUNT_TYPE"
            :value="option.value"
            v-bind:key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
      <div v-if="amountTypeEditable" class="cell">
        <input
          :disabled="state.reward.amount_type !== 2"
          v-model="state.reward.min_amount"
          type="number"
        />
      </div>
      <div v-if="probabilityEditable" class="cell">
        <input v-model="state.reward.probability" type="number" />
      </div>
      <div class="cell" v-if="onRemove">
        <Button
          size="md"
          type="error"
          text="Remove"
          :onClick="() => onRemove()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import rewardConstants from "../../constants/rewardConstants";
import unitConstants from "../../constants/unitConstants";
import Button from "./Button.vue";
export default {
  components: { Button },
  props: {
    hasHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableRewardTypes: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
    onRemove: {
      type: Function,
      required: false,
      default: undefined,
    },
    probabilityEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    amountTypeEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const state = reactive({ reward: JSON.parse(JSON.stringify(props.data)) });
    const columnCount = 4 + (props.probabilityEditable ? 1 : 0) + (props.amountTypeEditable ? 2 : 0) + (props.onRemove !== undefined ? 1 : 0);

    const rewardTypes = rewardConstants.ALL_REWARD_TYPES.filter((x) =>
      props.availableRewardTypes.includes(x.value)
    );

    watch(
      state,
      (newValue) => {
        props.onChanged(newValue.reward);
      },
      { immediate: true }
    );

    const store = useStore();
    const chests = computed(() => store.getters["chests/getChests"]);
    const units = computed(() => store.getters["units/getUnits"]);
    const heroes = computed(() => store.getters["heroes/getHeroes"]);

    const initializeRewardType = (type) => {
      const reward = {
        type,
        amount: 0,
        amount_type: 0,
      };
      if ([3, 4, 20].includes(type)) reward.rarity = 0;
      if ([6, 14].includes(type)) {
        delete reward.amount;
        delete reward.amount_type;
      }

      state.reward = reward;
    };

    const getIdColOption = (type) => {
      switch (type) {
        case 2:
          return chests.value;
        case 3:
          return [
            { id: undefined, name: "Random" },
            ...units.value.filter((x) => x.status === 1),
          ];
        case 4:
          return [
            { id: undefined, name: "Random" },
            ...heroes.value.filter((x) => x.status === 1),
          ];
        default:
          return [];
      }
    };

    return {
      chests,
      units,
      heroes,
      state,
      rewardTypes,
      getIdColOption,
      constants: {
        CARD_RARITY: unitConstants.RARITY,
      },
      initializeRewardType,
      rewardConstants,
      columnCount
    };
  },
};
</script>

<style scoped>
.reward-container {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cell {
  width: calc(100% / 4);
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

select {
  width: 90%;
  height: 35px;
  min-width: 100px;
}

input {
  width: 90%;
  min-width: 100px;
  height: 35px;
}

.underline {
  border: 1px bottom black;
  font-weight: 700;
}
</style>