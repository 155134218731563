<template>
  <div class="main-container">
    <Tabs :tabOptions="tabOptions" :onTabUpdated="(index) => switchTab(index)" />
    <div class="main-heading">
      <h2>{{ "Thematic Liveops" }}</h2>
      <Button
        :onClick="() => submit()"
        type="info"
        text="Submit"
        :disabled="!isDirty"
        :loading="isLoading"
      />
    </div>
    <div class="subcontainer" v-if="event">
      <main-page
        v-if="selectedTabIndex === 0"
        :data="event"
        :onChanged="(data) => onDataChanged(data)"
      />
      <event-pass
        v-if="selectedTabIndex === 1"
        :data="event"
        :isEventActive="isEventActive"
        :onChanged="(data) => onDataChanged(data)"
      />
      <shop
        v-if="selectedTabIndex === 2"
        :data="event"
        :isEventActive="isEventActive"
        :onChanged="(data) => onDataChanged(data)"
      />
      <roulette
        v-if="selectedTabIndex === 4"
        :data="event"
        :isEventActive="isEventActive"
        :onChanged="(data) => onDataChanged(data)"
        :availableRewardTypes="[0, 1, 2, 3, 4]"
      />
    </div>
    <new-confirm-modal
      :mode="modalMode"
      :isOpen="modalState"
      :changes="changes"
      :onReject="() => closeModal()"
      :onApprove="() => confirmSubmit()"
    />
  </div>
</template>


<script>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  onBeforeMount,
  watch,
  computed,
} from "vue";

//Event Pages
import MainPage from "./components/MainPage.vue";
import Roulette from "./components/Roulette.vue";
import Shop from "./components/Shop.vue";
import EventPass from "./components/EventPass.vue";

import { useStore } from "vuex";
import dispatchMap from "@/constants/dispatchMap";
import Button from "../../../components/common/Button.vue";
import { useThematicLiveops } from "./common/thematic-liveops-composable";
import { useChangeTracker } from "../../../composables/changeTracker";
import { useModal } from "../../../composables/modal";
import NewConfirmModal from "../../../components/common/NewConfirmModal.vue";
import Tabs from '../../../components/common/Tabs.vue';

export default {
  name: "ThematicLiveOpsMain",
  components: {
    MainPage,
    Button,
    Roulette,
    Shop,
    EventPass,
    NewConfirmModal,
    Tabs
  },
  setup() {
    const tabOptions = ["Main", "Event Pass", "Shop", "Quests", "Roulette"];
    const selectedTabIndex = ref(0);
    const store = useStore();
    const event = ref(null);

    const { isEventActive, setIsEventActive } = useThematicLiveops();
    const {
      isDirty,
      changes,
      updateTrackerState,
      checkChanges,
      onChangesSubmitted,
      isSync,
    } = useChangeTracker("ThematicLiveOpsMain", "", true);
    const { modalState, modalMode, openModal, closeModal } = useModal();

    const usedPages = ["events"];
    const events = computed(() => store.getters["events/getEvents"]);
    const isLoading = ref(false);

    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );

    const onEventSet = () => {
      if (!event.value) return;

      const now = Date.now();
      setIsEventActive(
        event.value.start_date < now && event.value.end_date > now
      );
      updateTrackerState(JSON.parse(JSON.stringify(event.value)));
    };

    onMounted(() => {
      if (events.value.length > 0) {
        event.value = JSON.parse(
          JSON.stringify(events.value.find((x) => x.type === 5) || {})
        );
        store.dispatch("loader/loadingStatus", false);
        onEventSet();
      }
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["events/getEvents"],
      (value) => {
        const data = [...value].find((x) => x.type === 5);
        if (data) event.value = JSON.parse(JSON.stringify(data));
        if (event.value) store.dispatch("loader/loadingStatus", false);
        onEventSet();
      },
      { immediate: true }
    );

    const onDataChanged = (data) => {
      event.value = data;
      updateTrackerState(data);
    };

    const switchTab = (index) => {
      selectedTabIndex.value = index;
    };

    watch(isSync, (value) => {
      if (!value) {
        openModal(1);
      }
    });

    const submit = () => {
      checkChanges();
      openModal(0);
    };

    const confirmSubmit = () => {
      closeModal();
      if (modalMode.value === 1) {
        window.location.reload();
        return;
      }
      isLoading.value = true;
      store
        .dispatch("events/updateEvent", {
          id: "thematic_liveops",
          updateBody: event.value,
        })
        .then(() => {
          onChangesSubmitted();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      tabOptions,
      selectedTabIndex,
      switchTab,
      event,
      onDataChanged,
      submit,
      confirmSubmit,
      isLoading,
      isEventActive,
      isDirty,
      modalState,
      closeModal,
      modalMode,
      changes,
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.menu-item {
  cursor: pointer;
  border-bottom: 1px solid grey;
  font-size: 15px;
  padding: 10px 20px;
  min-width: 200px;
}

.subcontainer {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  padding: 0px 30px;
}

.menu-item:hover {
  background-color: lavender;
}

.menu-item-selected {
  border-bottom: 1px solid grey;
  font-size: 15px;
  background-color: khaki;
  min-width: 200px;
  padding: 10px 20px;
}

.main-heading {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
</style>