<template>
  <div>
    <div class="header-with-button">
      <h4>Info Fields</h4>
      <div class="flex">
        <Button
          :loading="false"
          text="Get Defaults"
          size="sm"
          :onClick="() => getDefaultInfoFields()"
          type="info"
        />
        <Button
          :loading="false"
          text="Add"
          size="sm"
          :onClick="() => addInfoField()"
          type="success"
        />
        <Button
          :loading="false"
          text="Remove"
          size="sm"
          :onClick="() => removeInfoField(state.info_fields.length - 1)"
          type="error"
        />
      </div>
    </div>
    <div
      v-for="(field, index) in state.info_fields"
      v-bind:key="index"
      class="info-field-container"
    >
      <div class="form-container">
        <p>Icon</p>
        <select class="input" v-model.number="field.icon">
          <option
            v-for="type in constants.INFO_FIELD_ICONS"
            v-bind:key="type.value"
            :value="type.value"
          >
            {{ type.text }}
          </option>
        </select>
      </div>
      <div class="form-container">
        <p>{{ "Field Title" }}</p>
        <input class="input" v-model="field.name[selectedLanguage]" />
      </div>
      <div class="form-container">
        <p>{{ "Text" }}</p>
        <textarea class="input" v-model="field.text[selectedLanguage]" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import Button from "@/components/common/Button.vue";
import eventConstants from "@/constants/eventConstants";

export default {
  props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    onChanged: {
      type: Function,
      required: true,
    },
  },
  components: {
    Button,
  },
  setup(props) {
    const state = reactive(JSON.parse(JSON.stringify(props.data)));

    watch(
      state,
      (newData) => {
        if (newData) props.onChanged(newData);
      },
      { immediate: true }
    );

    const addInfoField = () => {
        state.info_fields.push({ icon:0, name: {}, text: {} })
    };

    const removeInfoField = (index) => {
        state.info_fields.splice(index, 1)
    };

    return {
      constants: {
        INFO_FIELD_ICONS: eventConstants.INFO_FIELD_ICONS,
      },
      addInfoField,
      removeInfoField,
      state
    };
  },
};
</script>

<style scoped>
.info-field-container {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  margin-bottom: 20px;
}

.header-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex {
  display: flex;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}

</style>